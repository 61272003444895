<template>
  <div class="ng-content-wrap">
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" :to="`/orders?user_id=${user_id}`">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>All {{ customer?.first_name }} Orders</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>

          <div class="ng-block-des">
            <h4 v-if="user_id">
              <b> Customer:</b>
              {{ customer?.first_name }}
            </h4>
            <h4 v-else>
              On
              <b> {{ fD(new Date(date)) }} </b>
            </h4>
            <h4>
              <b>Departure Date:</b>
              {{ fD(new Date(date), "E MMM do, yyyy") }}
              <br />
            </h4>
          </div>
        </div>

        <div class="ng-block-head-content">
          <div class="ng-block-des d-none d-print-block">
            <p>
              <span> <b>Order:</b> #{{ order?.number }} <br /> </span>
              <span>
                <b>Shipping Format:</b> {{ order?.format }}
                <br />
              </span>
              <span v-if="order?.bundles">
                <b>Strapped In Bundles:</b>
                {{ order?.bundles }}
                <br />
              </span>
              <span> <b>Freight Agent:</b> {{ order?.agent?.name }} </span>
            </p>
          </div>

          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <router-link
                :to="`/reports/day/${date}`"
                class="btn btn-primary btn-icon btn-round"
              >
                <em class="icon ni ni-bar-chart"></em>
              </router-link>
            </li>
            <!--            <li v-if="customers.length">-->
            <!--              <div class="input-group">-->
            <!--                <div class="input-group-prepend">-->
            <!--                  <span-->
            <!--                    class="input-group-text bg-primary text-white font-weight-bold"-->
            <!--                  >-->
            <!--                    <em class="icon ni ni-user-alt"></em>-->
            <!--                  </span>-->
            <!--                </div>-->
            <!--                <Multiselect-->
            <!--                  :options="customers"-->
            <!--                  v-model="user_id"-->
            <!--                  trackBy="name"-->
            <!--                  label="name"-->
            <!--                  valueProp="id"-->
            <!--                  :searchable="true"-->
            <!--                  placeholder="Start typing..."-->
            <!--                  @change="fetchAssignments"-->
            <!--                />-->
            <!--              </div>-->
            <!--            </li>-->
            <li v-if="user_id">
              <a class="btn btn-white btn-dim btn-outline-primary">
                <em class="icon ni ni-hash"></em>
                <span v-if="order?.number">
                  {{ order?.number }}
                </span>
                <span v-else>
                  <span class="d-none d-sm-inline-block"> Order </span>
                  Details
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="setNumber">
                <div class="modal-dialog modal-sm" role="document">
                  <div class="modal-content">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">
                        Purchase Order Details
                      </h5>
                    </div>

                    <div class="modal-body">
                      <div class="form-group">
                        <label> Freight Agent </label>
                        <Multiselect
                          :options="agents"
                          v-model="agent_id"
                          trackBy="name"
                          label="name"
                          valueProp="id"
                          :searchable="true"
                          placeholder="Start typing..."
                        />
                      </div>

                      <div class="form-group">
                        <label>Shipping Format</label>
                        <select class="form-control" v-model="order.format">
                          <option>Pallet</option>
                          <option>AKE</option>
                          <option>Loose</option>
                        </select>
                      </div>

                      <div class="form-group">
                        <label> Strapped In Bundles </label>
                        <input class="form-control" v-model="order.bundles" />
                      </div>

                      <div class="form-group">
                        <label> Purchase Order Number </label>
                        <input class="form-control" v-model="order.number" />
                      </div>

                      <div class="form-group justify-content-between">
                        <button
                          class="btn btn-primary float-right"
                          @click.prevent="confirmOrder"
                        >
                          <em class="icon ni ni-check"></em>
                          <span>
                            Confirm Order
                            <span v-if="order?.number">
                              {{ order?.number }}
                            </span>
                            <span v-else>
                              for
                              {{ fD(new Date(date), "MMM do") }}
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div class="card" :class="{ 'card-bordered': user_id }" v-else>
        <div class="card-content" v-if="user_id">
          <table
            class="table table-bordered table-tranx"
            v-if="assignments.length"
          >
            <thead class="tb-tnx-head">
              <tr class="tb-tnx-item">
                <th class="tb-tnx-info align-middle text-dark text-bold">
                  Product
                </th>
                <th class="text-left align-middle">Packaging</th>
                <th class="text-center align-middle">Box Weight</th>
                <th class="text-center align-middle text-dark text-bold">
                  Boxes Ordered
                </th>
                <th class="text-center align-middle text-dark text-bold">
                  Total Weight
                </th>
                <th
                  class="text-center align-middle"
                  v-if="order?.format !== 'Loose'"
                >
                  Boxes Per {{ order?.format }}
                </th>
                <th
                  class="text-center align-middle"
                  v-if="order?.format !== 'Loose'"
                >
                  Pallet Fill
                </th>
                <th class="text-left align-middle text-dark text-bold">
                  Unit Cost
                </th>
                <th class="text-left align-middle">Line Cost</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(p, i) in assignments" :key="i">
                <tr class="tb-tnx-item" v-if="(form[p.id]['count'] || 0) > 0">
                  <td class="tb-tnx-info align-middle">
                    <div class="tb-tnx-desc">
                      <span class="title text-dark text-bold">
                        {{ p.name }}
                      </span>
                    </div>
                  </td>

                  <td class="text-left align-middle">
                    {{ p.primary_packaging?.name }}
                  </td>

                  <td class="text-center align-middle">
                    {{
                      form[p.id]["uweight"].toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })
                    }}Kg
                  </td>

                  <td class="text-center align-middle text-dark text-bold">
                    {{ form[p.id]["count"] }}
                  </td>

                  <td class="text-center align-middle text-dark text-bold">
                    {{
                      form[p.id]["weight"].toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })
                    }}Kg
                  </td>

                  <td
                    class="text-center align-middle"
                    v-if="order?.format !== 'Loose'"
                  >
                    {{ p.box_type?.pallet?.boxes }}
                  </td>

                  <td
                    class="text-center align-middle"
                    v-if="order?.format !== 'Loose'"
                  >
                    <span>
                      {{
                        form[p.id]["boxes"].toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })
                      }}
                    </span>
                  </td>

                  <td class="text-left align-middle text-dark text-bold">
                    <sup>{{ getCurrency() }}</sup>
                    {{
                      (p[customer?.contact?.currency || "euro"] || 0).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })
                    }}
                  </td>

                  <td class="text-left align-middle">
                    <sup>{{ getCurrency() }}</sup>
                    {{
                      (
                        p[customer?.contact?.currency || "euro"] *
                        form[p.id]["count"]
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })
                    }}
                  </td>
                </tr>
              </template>

              <tr class="tb-tnx-item">
                <td class="text-center"></td>

                <td class="tb-tnx-info align-middle text-left">
                  <div class="tb-tnx-desc">
                    <b class="title"> TOTAL </b>
                  </div>
                </td>

                <td class="text-center"></td>

                <td class="text-center align-middle text-bold">
                  {{ getPackageSum("count") }}
                </td>

                <td class="text-center align-middle text-bold">
                  {{
                    getPackageSum("weight").toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })
                  }}Kg
                </td>

                <td class="text-center" v-if="order?.format !== 'Loose'"></td>

                <td class="text-center" v-if="order?.format !== 'Loose'">
                  {{
                    getPackageSum("boxes").toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })
                  }}
                </td>

                <td class="text-center"></td>

                <td class="text-left align-middle">
                  <sup>{{ getCurrency() }}</sup>
                  {{
                    getPackageSum("cost").toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="alert alert-warning text-center" v-else>
          <h5>
            <em class="icon ni ni-alert text-warning"></em>
          </h5>
          Please select a customer to confirm order for.
        </div>
      </div>

      <div class="mt-1" v-if="sent">
        <div class="alert alert-success">
          Email Has Been Sent Successfuly
        </div>
      </div>

      <div
        class="mt-2 pr-3 d-flex justify-content-between"
        v-if="assignments.length"
      >
        <button
          v-if="sale?.id"
          class="btn btn-primary d-print-none mr-3"
          :disabled="!order?.customer?.contact?.reps"
          @click.prevent="emailOrder"
        >
          <em class="icon ni ni-mail"></em>
          <span v-if="sending" class="spinner spinner-border spinner-sm">
          </span>
          <span v-else>
            Email Order
            <span v-if="order?.number">
              {{ order?.number }}
            </span>
            <span v-else> for {{ fD(new Date(date), "MMM do") }} </span>
          </span>
        </button>

        <button
          class="btn btn-primary float-right d-print-none"
          v-else
          @click.prevent="confirmOrder"
        >
          <em class="icon ni ni-check"></em>
          <span>
            Confirm Order
            <span v-if="order?.number">
              {{ order?.number }}
            </span>
            <span v-else> for {{ fD(new Date(date), "MMM do") }} </span>
          </span>
        </button>
      </div>
      <div v-if="sale?.id" class="pr-3 d-flex mt-4">
        <div class="mr-4">
          <table
            class="table table-tranx table-bordered col-4"
            v-if="order?.customer?.contact?.reps"
          >
            <thead class="tb-tnx-head">
              <tr class="tb-tnx-item">
                <th class="tb-tnx-info align-middle">
                  <h6 class="text-left">Email Recipients</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="tb-tnx-item"
                v-for="(rep, i) in order?.customer?.contact?.reps"
                :key="i"
              >
                <td class="tb-tnx-info align-middle">
                  <h6 class="text-left">{{ rep.name }}: {{ rep.email }}</h6>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table table-tranx table-bordered col-4" v-else>
            <thead class="tb-tnx-head">
              <tr class="tb-tnx-item">
                <th
                  class="tb-tnx-info align-middle alert alert-warning text-center"
                >
                  <h6 class="text-left">
                    <em class="icon ni ni-alert text-warning mr-2"></em>Customer
                    has no contact personnel
                  </h6>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="ml-4">
          <table
            v-if="emails.length > 0"
            class="table table-tranx table-bordered col-4"
          >
            <thead class="tb-tnx-head">
              <tr class="tb-tnx-item">
                <th class="tb-tnx-info align-middle">
                  <h6 class="text-left">Notification Recipients</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="tb-tnx-item" v-for="(rep, i) in emails" :key="i">
                <td class="tb-tnx-info align-middle">
                  <h6 class="text-left">{{ rep.name }}: {{ rep.email }}</h6>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table table-tranx table-bordered col-4" v-else>
            <thead class="tb-tnx-head">
              <tr class="tb-tnx-item">
                <th
                  class="tb-tnx-info align-middle alert alert-warning text-center"
                >
                  <h6 class="text-left">
                    <em class="icon ni ni-alert text-warning mr-2"></em>
                    There are no notification emails setup
                  </h6>
                </th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch
} from "vue";
import moment from "moment";
import router from "../../router";
import { format } from "date-fns";
import Input from "common/components/Input.vue";
import func from "vue-temp/vue-editor-bridge";

export default defineComponent({
  props: ["day"],
  setup(props) {
    const form = ref({});

    const assignments = ref([]);
    const emails = ref([]);

    const search = ref("");
    const assignment = reactive({
      name: "",
      code: ""
    });

    const order = ref({ id: 0, format: "Pallet", number: "", bundles: 0 });
    const sale = ref({ id: 0 });
    const depart = ref(new Date());

    const agents = ref([]);
    const agent_id = ref(0);
    const agent = computed(() => {
      return agents.value?.find((a: any) => a.id == agent_id.value) || {};
    });

    const customers = ref([]);
    const user_id = ref(0);
    const customer = computed(() => {
      return (
        customers.value?.find((c: any) => c.id == user_id.value) || {
          contact: { currency: "euro" }
        }
      );
    });

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    const sent = ref(false);
    const sending = ref(false);

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    const date = ref(fD(new Date(props.day), "yyyy-MM-dd"));

    function getPackageSum(section = "count") {
      const data = Object.values(form.value);

      return data.reduce((o: any, n: any) => {
        return o + parseFloat(n[section]);
      }, 0);
    }

    function getFillSum(section = "count") {
      const data = Object.values(form.value);

      return data.reduce((o: any, n: any) => {
        return o + parseFloat(n[section]);
      }, 0);
    }

    function getCurrency() {
      if (customer.value.contact?.currency == "euro") {
        return "€";
      } else if (customer.value.contact?.currency == "pound") {
        return "£";
      } else if (customer.value.contact?.currency == "dollar") {
        return "$";
      } else if (customer.value.contact?.currency == "shilling") {
        return "KSh";
      }
    }

    function fetchAgents() {
      processing.value = true;
      http.get(`/api/users?role=agent&fetch=all`).then(res => {
        agents.value = res.data;
        processing.value = false;
      });
    }
    function fetchNotificationEmails() {
      processing.value = true;
      http.get(`/api/notification-emails`).then(res => {
        emails.value = res.data.data;
        processing.value = false;
      });
    }

    function fetchCustomers() {
      processing.value = true;
      http.get(`/api/users?role=customer&fetch=all`).then(res => {
        customers.value = res.data;
        processing.value = false;
      });
    }

    function fetchSale() {
      http
        .get(`/api/sales?order_id=${order.value?.id}&fetch=all`)
        .then((res: any) => {
          if (res.data[0]) {
            sale.value = res.data[0];
          }
        });
    }

    async function fetchOrders(user: any) {
      const res = await http.get(
        `/api/orders?user_id=${user}&date=${date.value}&fetch=all`
      );

      order.value = res.data[0] || {};

      fetchSale();

      return res.data;
    }

    function fetchAssignments(id: any) {
      processing.value = true;

      fetchOrders(id).then((os: any) => {
        http.get(`/api/assignments?user_id=${id}&fetch=all`).then(res => {
          const a = res.data;
          assignments.value = a;
          const order: any = os[0];

          depart.value = new Date(order.depart);

          form.value = a.reduce(
            (r: any, p: any) => ({
              ...r,
              [p.id]: {
                count: order?.quantities ? (order?.quantities as any)[p.id] : 0,
                weight:
                  (order?.quantities ? (order?.quantities as any)[p.id] : 0) *
                  ((p.weight * p.count) / 1000),
                boxes:
                  (order?.quantities ? (order?.quantities as any)[p.id] : 0) > 0
                    ? (order?.quantities as any)[p.id] /
                      (p.box_type?.pallet?.boxes || 0)
                    : 0,
                uweight: (p.weight * p.count) / 1000,
                cost:
                  (order?.quantities ? (order?.quantities as any)[p.id] : 0) *
                  p[customer.value?.contact?.currency || "euro"]
              }
            }),
            {}
          );
          processing.value = false;
        });
      });
    }

    function confirmOrder() {
      http
        .post("/api/sales", {
          user_id: user_id.value,
          order_id: order.value?.id,
          quantities: form.value,
          number: order.value?.number,
          amount: getPackageSum("cost"),
          date: fD(new Date(), "yyyy-MM-dd"),
          depart: depart.value
        })
        .then(res => {
          if (res.data.id) {
            created.value = true;
            sale.value = res.data;
          }
        })
        .finally(() => {
          fetchAssignments(user_id.value);
        });
    }

    function emailOrder() {
      sending.value = true;
      http
        .post(`/api/confirmation/${sale.value?.id}`)
        .then(res => {
          sending.value = false;
          sent.value = true;
          return res;
        })
        .catch(() => {
          sent.value = false;
          sending.value = false;
        });
    }

    onMounted(() => {
      fetchAgents();
      fetchCustomers();
      fetchNotificationEmails();

      if (router.currentRoute.value.query?.user_id) {
        const u = router.currentRoute.value.query?.user_id;

        user_id.value = Number(u);
        fetchAssignments(u);
      }
    });

    watch(
      () => date.value,
      (v: any) => {
        fetchAssignments(user_id.value);
      }
    );

    return {
      fetchAssignments,
      processing,
      sending,
      sent,
      search,
      assignments,
      confirmOrder,
      emailOrder,
      created,
      fD,
      date,
      form,
      customers,
      customer,
      user_id,
      getPackageSum,
      getCurrency,
      order,
      sale,
      agents,
      agent,
      agent_id,
      depart,
      emails
    };
  }
});
</script>

<style scoped>
.text-bold {
  font-weight: bolder;
}
</style>
